.Recordings-screen {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.Recordings-title {
    padding-right: 20px;
    text-align: right;
    font-size: 24px;
}

.Recordings-stream {
    margin: 20px;
}

.Recordings-stream-title {
    background: #f8f7f3;
    max-width: 160px;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid #f8f7f3;
}

.Recordings-stream-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-evenly;

    background: #f8f7f3;
    padding: 40px;

    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 2px solid black;
}

.Recordings-mix {
    display: flex;
    flex: 1;

    min-width: 250px;
    /* max-width: 600px; */
    border-radius: 10px;
    border: 4px solid black;
    background-color: #fba6e9;
}

.Recordings-mix-expanded {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: 10px;
    /* max-width: 600px; */
    border-radius: 10px;
    border: 4px solid black;

    background-color: #fba6e9;
}

.Recordings-mix-img {
    height: 100%;
}

.Recordings-mix-img-expanded {

    border: 2px solid black;
    border-radius: 10px;
}

.About-screen {
    padding: 20px;
}

.About-text {
    font-size: 20px;
}

.style1 {
    font-size: 10px;
    color: #cccccc;
    line-break: anywhere;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ;
    font-weight: 100;
}