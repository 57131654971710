.Stream-screen {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    padding: 20px;
}

.Stream-video {
    min-width: 200px;
    flex: 2;
}

.Stream-slate {
    width: 100%;
    max-width: 800px;
}

.Stream-chat {
    min-width: 200px;
    flex: 1;
}

.Stream-chat-iframe {
    width: 100%;
    border: 1px solid black;
}
