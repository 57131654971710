.Audio-screen {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;

    padding: 20px;
}

.Audio-player {
    display: flex;
    justify-content: center;
    align-items: center;
    
    min-width: 200px;
}

.Audio-chat {
    min-width: 200px;
}

.Audio-chat-iframe {
    width: 100%;
    border: 1px solid black;
}