html {
  height: 100%
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.App {
  display: flex;
  flex: 1;
  min-height: 100%;
  flex-direction: column;
  text-align: center;

  width: 100%;

  align-items: center;
  font-family: monospace;
}

.App-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  width: 100%;


  align-items: center;
  font-family: monospace;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  width: 100%;
  min-height: 40px;
  padding: 10px;
  background-color: #fba6e9;

  border-bottom: solid 3px black;

  font-size: 24px;
}


.App-logo {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;

  flex-wrap: wrap-reverse;
  align-items: center;
  min-height: 100px;
  gap: 20px;

  align-content: center;

  margin: 20px;
  padding: 40px;
}

.App-logo-container {
  display: flex;
}

.App-logo-title {
  font-family: "Monoton";
  font-size: 60px;
}

.App-content {
  width: 90%;
  max-width: 1500px;
  margin: 20px;

  font-size: 20px;
}

.App-viewer-title {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  margin-right: 20px;

  background: white;
}

.App-viewer-title-button {
  background: #a88599;

  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px solid black;
}

.App-viewer-title-chosen-button {
  background: #fba6e9;

  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px solid black;
}

.App-viewer-title-button-bottom {
  position: absolute;

  background: #fba6e9;
  margin-top: 10px;
  margin-left: -10px;
  width: 61px;
  height: 3px;
}


.App-viewer {
  width: 100%;

  background: #fba6e9;
  border-radius: 10px;
  border: 3px solid black;
}

.App-link {
  color: #61dafb;
}

.Viewmode-buttons {
  display: flex;
}

.App-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  /* margin-top: 100px; */
  max-width: 1500px;
  margin: 20px 20px 0 20px;
  padding: 20px;
  gap: 20px;

  background: #fba6e9;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 3px solid black;
  border-bottom: 0;

  font-size: 20px;
}

.App-footer-socials {
  flex: 1;
}

.App-footer-title {
  font-family: 'Monoton';
  font-size: 30px;
}

.App-footer-socials-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;

  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid black;
}

.logo_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-footer-schedule {
  flex: 1;
}

.App-footer-schedule-inner {
  display: flex;
  justify-content: center;

  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid black;

}

.About-screen {
  padding: 20px;
}

.About-text {
  font-size: 20px;
}

a:link {
  background-color: transparent;
  text-decoration: none;
}